<template>
    <v-chip-group>
        <v-chip v-for="role in roles" small :dark="dark" :color="role.color" :key="role.value">
            {{ role.text }}
        </v-chip>
    </v-chip-group>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import OrderBy from 'lodash/orderBy';
export default {
    name: 'label-roles',
    data: () => ({}),
    created() {},
    mounted() {},
    props: {
        value: {},
        color: {
            default: null,
        },
        dark: {
            default: true,
        },
    },
    watch: {},
    computed: {
        roles() {
            if (this.value) {
                return OrderBy(
                    this.value.map((r) => {
                        return {
                            text: this.$t('lists.roles.' + r),
                            value: r,
                            color: this.roleColor(r),
                        };
                    }),
                    ['value'],
                    ['asc'],
                );
            }
            return [];
        },
    },
    methods: {
        roleColor(role) {
            if (this.color) {
                return this.color;
            }
            if (role === 'productOwner') {
                return 'orange darken-2';
            }
            if (role === 'productManager') {
                return 'indigo darken-2';
            }

            return 'light-blue darken-2';
        },
    },
};
</script>
