<template>
    <v-select v-model="localStatus" :items="statusToDisplay" :label="label" chips multiple></v-select>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import OrderBy from 'lodash/orderBy';

export default {
    name: 'selector-status',
    data: () => ({
        localStatus: [],
    }),
    created() {},
    mounted() {
        this.localStatus = this.value;
    },
    props: {
        value: {},
        label: {},
        type: {
            default: 'id',
        },
    },
    watch: {
        localStatus: function (value) {
            this.$emit('input', value);
        },
        value: function (value) {
            this.localStatus = value;
        },
    },
    computed: {
        ...mapGetters(['status']),
        statusToDisplay() {
            if (this.status) {
                return OrderBy(
                    this.status.map((s) => {
                        return {
                            text: s.label,
                            value: this.type === 'id' ? s.id : s.name,
                        };
                    }),
                    ['text'],
                    ['asc'],
                );
            }
            return [];
        },
    },
    methods: {},
};
</script>
