<template>
    <v-select
        v-model="localAttributes"
        :items="selector"
        chips
        :label="label"
        multiple
        :outlined="outlined"
        deletable-chips
    ></v-select>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import OrderBy from 'lodash/orderBy';

export default {
    name: 'selector-issue-attributes',
    data: () => ({
        localAttributes: [],
    }),
    created() {},
    mounted() {
        this.localAttributes = this.selector.filter((s) => this.value.includes(s.value)).map((s) => s.value);
    },
    props: {
        value: {},
        label: {},
        outlined: {
            default: false,
        },
    },
    watch: {
        localAttributes: function (value) {
            this.$emit('input', value);
        },
        value: function (value) {
            this.localAttributes = value;
        },
    },
    computed: {
        ...mapGetters(['issueAttributes']),
        selector() {
            return OrderBy(
                this.issueAttributes.map((a) => {
                    return {
                        value: a,
                        text: this.$t('issues.attributes.' + a),
                    };
                }),
                ['text'],
                ['asc'],
            );
        },
    },
    methods: {},
};
</script>
